.btnIngresar {
  width: auto;
  background-color: rgb(189 31 52 / 96%)
}


.ContenedorTotal {
  position: absolute;
  left: 15px;
}

.hijosfooter {
  flex: 3;
  margin: 0px;
  text-align: justify;
  border: red;
  height: 75px;
}

.afooter {
  color: black;
}

.hijosfooterv2 {
  /*width: 50px;
  margin: 20px;*/
}


.btn-floating-whatsapp {
  background-color: rgb(189 31 52 / 96%) !important;
  bottom: 11rem !important;
}


.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  padding: 10px;
}
.imgheader{
  width: 100%;
}

.content {
  flex-wrap: wrap !important;
  padding: 10px;
  display: grid !important;
}



.footerFotos {
  border-top: 1px solid rgba(189, 31, 52, 0.96);
  height: 250px;
  flex-wrap: wrap;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}


.footerv2 {
  background-color: #f1f1f1;
  padding: 10px;
}

.footer {
  border-top: 1px solid rgba(189, 31, 52, 0.96);
  max-width: none;
  height: 75px;
  left: 0;
  position: absolute;
  right: 0;
  flex-wrap: wrap;
  display: grid;
  margin-top: 15vh;
}


.ContenedorImagen {
  max-width: none;
  flex-wrap: wrap;
  display: grid;
  max-width: 85% !important;
  
  justify-content: center;
  align-items: center;
  display: flex !important;
}


.hijosImagen {
  flex: 3;
  margin: 15px;
  max-width: 145px !important;
  float: left;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.Maestro{
  padding: 0px !important;
}

@media only screen and (max-width: 500px) {
  body {
    /* background-color: rgb(255, 0, 0) !important;*/
  }
  .hijosImagen {
    flex: 1;
    margin: 20px;
    max-width: 270px !important;
    float: left;
    display: flex !important;
  }
  .footer {
   
  }
}



@media only screen and (max-width: 1400px) {
  body {
    /* background-color: rgb(255, 0, 0) !important;*/
  }
  .hijosImagen {
    flex: 2;
    margin: 20px;
    max-width: 270px !important;
    float: left;
    display: flex !important;
  }
  .footer {
   
  }
}


.ContenedorLogin{
  max-width: 600px !important;
  text-align: start;
}