@media only screen and (max-width: 800px) {
    body {
      /* background-color: rgb(165, 216, 226) !important;*/
    }
    .hijosfooterv2 {
        width: 42.3% !important;
        padding: 1px !important;
    }
    .hijosfooterv3 {
      width: 90.3% !important;
      padding: 1px !important;
  }
}
