.Menu{
  padding-top:120px;
}

.BuscadorTablaOculto{
  display: none !important;
}

.css-7sh90e-MuiTableCell-root{
  line-height: 16px;
  list-style: none;
  padding: 5px;

}
.TablaContenedor{
  max-width: 100%;
}

.ContenedorBuscadorTabla{
  margin-left: 1px;
}

.ContenedorTabla{
  max-width: 100%;
}

.MenuBorroso{
  padding-top: 100px;
 /* -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  width: 100px;
  height: 100px;*/
}

.MenuPaper {
    height: 100vh;
    width: 350px;
    background-color:#746e6e0f;
    display: table;
  }
  .HijoMenu{
    display: table-cell;
  }
  
  .NewClass {
    display: table;
    height: 100vh;
    width: 0;
    animation: hide 1s forwards;
    margin: 0 !important;
  }
  
  /*@keyframes hide {
    from { opacity: 1; }
    to { opacity: 0; }
  }
*/
  @keyframes hide {
    from { width: 350px; }
    to { width: 0; }
    
  }


  .PaperMenu{
    width: 110%;
  }
  .newPaperMenu{
    display: none;
    width: 110%;
    animation: hide 1s forwards;
  }

  .logo{
    display: table-cell;
    width: 100%;
  }

  